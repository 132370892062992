import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Gimg from "../../Assets/images/DFL/google-review.png";
import googleReview from "../../Assets/images/home/google-review-1.png";
import googleReviewUser from "../../Assets/images/DFL/profile.webp";
import Rating from '@mui/material/Rating';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Img38 from '../../Assets/images/DFL/40-year.webp';
import LazyLoad from 'react-lazy-load';
import { isMobile } from 'react-device-detect';

const UserReview = [
    {
        "username": "Katie H",
        "rating": 5,
        "review": "Kevin and Armen helped my fiancé design my ring. They were so helpful with guiding him and providing advice throughout the process. My fiancé wasn't sure what type of ring I would like, so Kevin offered my fiancé the option to make changes…",
    },
    {
        "username": "Rute Leite Ferreira",
        "rating": 5,
        "review": "My fiancé went here to get my engagement ring, and since then, I’ve returned for a diamond necklace, earrings, and to explore wedding bands. Every visit has been wonderful. Kevin is such a great person and I love meeting with him to explore...",
    },
    {
        "username": "Julia C",
        "rating": 5,
        "review": "My partner and I had the pleasure of working with Andreas. We got a beautiful engagement ring and came back to get our wedding bands afterwards. Highly recommend DFL to anyone looking for that stunning engagement ring or wedding bands!",
    },
    {
        "username": "haleh shahsa",
        "rating": 5,
        "review": "We got our wedding bands and my engagement ring from them! It was such an amazing experience to work with Armen! He well informed us about any details and potential concerns! Last but not least, probably the best price for the quality you can find in the city!",
    },
    {
        "username": "Seth Patterson",
        "rating": 5,
        "review": "Diamonds for Less was the first place we went to start our search for an engagement ring. And the search pretty much stopped there. They were recommended to us by a friend and even though we were only in Toronto for a couple of weeks for work...",
    },
    {
        "username": "Ketki Bhavsar",
        "rating": 5,
        "review": "My fiancé purchased my engagement ring from DFL, and I couldn’t be happier with the final result. It turned out exactly as I had envisioned, thanks to Andreas incredible help and expertise. The entire DFL team provided outstanding customer service and offered truly unique products.",
    },
    {
        "username": "Trish Lewis",
        "rating": 5,
        "review": "My experience with Diamonds for Less has been nothing short of fantastic! My fiancé and I stumbled upon this gem while searching for my engagement ring, and we were amazed by the quality, prices, and excellent customer service.",
    },
    {
        "username": "SM J",
        "rating": 5,
        "review": "Don’t go anywhere else!! This is the place to go if you’re looking for stunning diamonds, at an unbeatable price with excellent customer service. This was the first, and only, place I needed to go to whilst looking for an engagement ring.",
    },
    {
        "username": "Jecca",
        "rating": 5,
        "review": "Writing a review here because I couldn’t stand the awful reviews written to this amazing business who had nothing but courtesy and authenticity upon assisting my fiancé with purchasing my engagement ring. This ring was brought all the way …",
    },
    {
        "username": "Guida Gaspar",
        "rating": 5,
        "review": "After 14 years, we decided to change my diamond ring to a different band.  Diamonds for less is the only place I go to because they are trustworthy and take great care in ensuring everything runs smoothly. Alex was a pleasure to deal with. …",
    },
    {
        "username": "Ahelan S",
        "rating": 5,
        "review": "Amazing ring, nothing beats the price. I tried buying online from an American retailer, but the exchange rate it so high.. This ring was 30% cheaper for the exact same ring.",
    },
    {
        "username": "Jeana Lee",
        "rating": 5,
        "review": "After visiting tons of places for an engagement ring for my girlfriend, i visited DFL and immediately knew I was at the right place. Andreas was extremely thorough with the ring selection process and provided me with all the information needed to make my decision. The ring was exactly what I had imagined and my girlfriend loved the ring. I would definitely recommend people to visit DFL before making their decision because you won’t get a better value for your budget anywhere else in Toronto.",
    },
    {
        "username": "Issaraporn Kansa",
        "rating": 5,
        "review": "Just got my engagement ring. It’s just stunning. Andres is really nice and helpful. He helped us get the finest option within our budget. Highly recommended. If I ever want to buy a diamond again, I will definitely come back here.",
    },

    {
        "username": "Tapan Hamal",
        "rating": 5,
        "review": "After visiting tons of places for an engagement ring for my girlfriend, i visited DFL and immediately knew I was at the right place. Andreas was extremely thorough with the ring selection process and provided me with all the information needed to make my decision. The ring was exactly what I had imagined and my girlfriend loved the ring. I would definitely recommend people to visit DFL before making their decision because you won’t get a better value for your budget anywhere else in Toronto.",
    },
    {
        "username": "Mar M",
        "rating": 5,
        "review": "Kevin is the man! Excellent attentive service! Could not ask for more. I love the ring so much so does my fiancée!! Only buy your diamonds here, 5 Star Service all the way!! Great selection of GIA graded diamonds.",
    },
    {
        "username": "Jonathan McFaul",
        "rating": 5,
        "review": "120% would recommend Diamonds for Less. I had an absolutely incredible experience. They have incredible staff, location, are strong with communication and most importantly, they have amazing diamonds. I had an incredible experience getting an engagement ring through Armen. They offered the best ring for the best price by far and you won't regret going to them.",
    },
    {
        "username": "Gail Marshall",
        "rating": 5,
        "review": "On a whim my husband and I visited Diamonds for Less and as a result I have the most stunning ring I could ever have dreamed of wearing. I love yellow gold and they created a fabulous, unique ring that fills me with joy!!",
    },
    {
        "username": "Fazzy Y",
        "rating": 5,
        "review": "I got referred by a friend to go to DFL. My fiancé and I have never been happier we chose them and had we went elsewhere and then found out about DFL we wouldn’t forgive ourselves. From what I heard from my fiancé (since he went alone to choose the ring) they were so accommodating and worked with him to find something we both loved and will cherish.",
    },
    {
        "username": "Lisamarie V",
        "rating": 5,
        "review": "So in love with my engagement ring. Everything about it is just perfect. So glad my fiancé found them to help him pick the perfect diamond. They resized it for me super quick as well. Amazing service.",
    },
    {
        "username": "Robyn Trott",
        "rating": 5,
        "review": "What a wonderful experience dealing with Kevin and his business partners! The diamond selection process is completely transparent, a literal calculation based on the qualities you choose for your stone. I would not hesitate to recommend Diamonds For Less to anyone wanting to purchase a diamond and I will be a repeat customer in the future. Thank you for a wonderful shopping experience and a beautiful diamond I am so pleased to wear!",
    },
    {
        "username": "Masoud Madannejad",
        "rating": 5,
        "review": "I purchased an engagement ring from Diamonds For Less. Their service went beyond my expectations. They created the exact ring I wanted to buy from James Allen, for a lesser price! Alex was the person I was in contact with. He was  very responsive and professional. He made sure that he created the exact ring I wanted with perfection. We are definitely coming back here for our wedding bands!",
    },

    {
        "username": "Blair Rossi",
        "rating": 5,
        "review": "I Recently worked w Alex to find the perfect engagement ring! He made the entire experience from start to finish effortless, stress free and very much enjoyable. The family atmosphere throughout my visits was very much present, and calming. They made me feel very comfortable and welcome. I’d recommend Alex and Diamonds for Less without hesitation.Thank you again!",

    },

    {
        "username": "Lise Cote",
        "rating": 5,
        "review": "Serko at Diamond for Less was wonderful.  Showed us some beautiful pieces and ended up choosing a stunning eternity ring.  He also got is sized and the appraisal done super quickly.  Thanks from Trevor and I.",

    },

    {
        "username": "Jonathan McFaul",
        "rating": 5,
        "review": "120% would recommend Diamonds for Less. I had an absolutely incredible experience. They have incredible staff, location, are strong with communication and most importantly, they have amazing diamonds. I had an incredible experience getting an engagement ring through Armen. They offered the best ring for the best price by far and you won't regret going to them.",
    },

    {
        "username": "Gail Marshall",
        "rating": 5,
        "review": "On a whim my husband and I visited Diamonds for Less and as a result I have the most stunning ring I could ever have dreamed of wearing. I love yellow gold and they created a fabulous, unique ring that fills me with joy!! I cannot wait to visit again to see what other treasures they can create! I highly recommend Diamonds for Less as they truly are creators of beautiful jewellery that is very fairly priced. Thank you so much for you assistance and the fabulous service we received.",
    },

    {
        "username": "Lisamarie V",
        "rating": 5,
        "review": "2 months ago So in love with my engagement ring. Everything about it is just perfect. So glad my fiancé found them to help him pick the perfect diamond. They resized it for me super quick as well. Amazing service.",
    },

    {
        "username": "Javier Machado",
        "rating": 5,
        "review": "I am a happy customer. I went there with a tied budget and little knowledge on Jewellery/rings and, they help me every step of the way to get a great engagement ring. On top of that, they change the size of the ring afterwards, at no additional cost.The have nice products, and they have great customer service. Highly recommended.",
    },

    {
        "username": "Stephanie De Santis",
        "rating": 5,
        "review": "You can tell the difference when people care about their work, it comes out in all aspects of what they do. Thank you to the entire team for the outstanding product, quality and service and of course, for always taking the time to walk me through the process and answer all my questions.  I have bought many items here over the years for some of life’s most special occasions because I trust the work they do and they have such great product and selection. I like to go back here to polish my rings,  because they they always give back my ring with a little extra sparkle and the people are so friendly.",
    },



]




const Customer = () => {
    const [customerData, setCustomerData] = React.useState([])

    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        centerMode: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <>
        
      {/* <LazyLoad className='lazyLoad-heroBanner' height={!isMobile?610:487} width="100%" threshold={ 0.90}> */}
            <section className="rcs_customer_say_section">

                <Container className="rcs_custome_container ">
                    <Row className='align-items-center'>
                        <Col lg={3} className="Dfl_review_oredr_1 d-flex align-items-center">
                            <div className='Dfl_g_rating_box'>
                                <LazyLoadImage className="vert-move" alt="anniversary" src={Img38} />
                            </div>
                        </Col>
                        <Col lg={9} className="Dfl_review_oredr_2">
                            <div className='dfl_review_bg'>

                                {/* <img className='dfl_review_since_img' src={Since} /> */}

                                <Row>
                                    <Col lg="12" col="12" className=''>
                                        <div className="rcs_customer_say_title ">
                                            <div className="rcs_customer_google_rating_img">
                                                <Link to="#" onClick={() => window.open("https://www.google.com/search?gs_ssp=eJzj4tZP1zcsSclONrTINmC0UjGosLBMMUlOMjZJNDUwMDO0MLcyqDBPMTc3TTRLNDUzMUwzSvESTMlMzM3PSylWSMsvUshJLS4GAK4qFIo&q=diamonds+for+less&rlz=1C5CHFA_enIN947IN947&oq=diamonds+for+less&aqs=chrome.1.0i355i512j46i175i199i512j0i512l4j0i22i30j69i61.4551j0j7&sourceid=chrome&ie=UTF-8#lrd=0x89d4cb34a5006187:0x7d775a6a5641f2d,1", "_blank")}>
                                                    <LazyLoadImage alt="Google Image" src={Gimg} />
                                                    <span className='Dfl_g_rev_number'> 1,224+ </span>  </Link>
                                            </div>
                                            <div className='dfl_no_rev'>
                                                <h3 >Most Reviewed In Canada</h3>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="12" col="12" className=''>
                                        <div className="rcs_customer_review_slider">
                                            <Slider className="rcs_customer_say_inner" {...settings2}>

                                                {UserReview.length > 0 && UserReview?.map(customdata =>
                                                    <div className='dfl_customer_rev_box' key={customdata?.username}>
                                                        <div className="rcs_customer_review_slide_item">
                                                            <div>
                                                                <div className='dfl_customer_rev_user'>
                                                                    <div className="rcs_customer_review_slide_img">
                                                                        <LazyLoadImage src={googleReviewUser} />
                                                                    </div>
                                                                    <div className="rcs_customer_review_slide_title">
                                                                        <p>{customdata?.username}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="rcs_customer_slide_title_img">
                                                                    <div className="rcs_customer_review_slide_title">
                                                                        <Rating name="half-rating-read" defaultValue={5} precision={customdata?.rating} size="medium" readOnly />
                                                                    </div>
                                                                </div>
                                                                <div className="rcs_customer_slide_text_content">
                                                                    <p>
                                                                        {(customdata?.review)?.slice(0, 150)}{customdata?.review?.length > 150 ? '...' : ""}
                                                                    </p>
                                                                    <Link to="#" onClick={() => window.open("https://www.google.com/search?gs_ssp=eJzj4tZP1zcsSclONrTINmC0UjGosLBMMUlOMjZJNDUwMDO0MLcyqDBPMTc3TTRLNDUzMUwzSvESTMlMzM3PSylWSMsvUshJLS4GAK4qFIo&q=diamonds+for+less&rlz=1C5CHFA_enIN947IN947&oq=diamonds+for+less&aqs=chrome.1.0i355i512j46i175i199i512j0i512l4j0i22i30j69i61.4551j0j7&sourceid=chrome&ie=UTF-8#lrd=0x89d4cb34a5006187:0x7d775a6a5641f2d,1", "_blank")}>  Read More </Link>
                                                                    <LazyLoadImage onClick={() => window.open("https://www.google.com/search?gs_ssp=eJzj4tZP1zcsSclONrTINmC0UjGosLBMMUlOMjZJNDUwMDO0MLcyqDBPMTc3TTRLNDUzMUwzSvESTMlMzM3PSylWSMsvUshJLS4GAK4qFIo&q=diamonds+for+less&rlz=1C5CHFA_enIN947IN947&oq=diamonds+for+less&aqs=chrome.1.0i355i512j46i175i199i512j0i512l4j0i22i30j69i61.4551j0j7&sourceid=chrome&ie=UTF-8#lrd=0x89d4cb34a5006187:0x7d775a6a5641f2d,1", "_blank")} src={googleReview} />
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                )}
                                            </Slider>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* </LazyLoad> */}
            {/* 
            <div className='dfl_ani_img_section'>
                <img src={Ani38} />
            </div> */}


        </>
    );
}

export default React.memo(Customer);




